import React from "react";
import CardStats from "../Cards/CardStats";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
// import CardBarChart from "../Cards/CardTable"

export default function HeaderStats(props) {




  return ( 
    <>
      {/* Header */} 
      <div className="relative bg-lightred-500 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
              {!props.users &&
                <Skeleton count={4}></Skeleton>

                }
               {props.users && 
               <Link to="/admin/visitors">
               <CardStats
                  statSubtitle="Visitors"
                  statTitle={props.users.visitors.length}
                  statPercentColor="text-emerald-500"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
                </Link>
                }
              </div>
              {props.users && props.users.pricingPlan==="Business" && <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
              {!props.users &&
                <Skeleton count={4}></Skeleton>

                }
                {props.users && 
                <Link to="/admin/employees">
                <CardStats
                  statSubtitle="Employees"
                  statTitle={props.users.employees.length}
                  statPercentColor="text-red-500"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
                </Link>
                }
              </div>}
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                {!props.users &&
                <Skeleton count={4}></Skeleton>

                }
                {props.users && 
                <Link to={"/"+localStorage.getItem("fisitorKey")+"/checkout"}>
                <CardStats
                  statSubtitle="Checked-in visitors"
                  statTitle={props.users.visitors.filter(visitor =>visitor.status === "checked in").length}
                  statPercentColor="text-red-500"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-pink-500"
                />
                 </Link>
                }
               
              </div>
            </div>
          </div>
          {/* <CardBarChart /> */}
        </div>
      </div>
    </>
  );
}
