import React, {Component} from "react";
import "tailwindcss/tailwind.css"
import {signInWithEmailAndPassword} from 'firebase/auth'
import {auth} from '../../firebase-auth'
import Swal from "sweetalert2";  
import { withRouter,Link } from 'react-router-dom';
import { EyeIcon } from "@heroicons/react/solid";
import { EyeOffIcon } from "@heroicons/react/solid";
import LoadingOverlay from 'react-loading-overlay';
import {getAuth,onAuthStateChanged} from 'firebase/auth'
import slugify from 'react-slugify';


export class Login extends Component {
  state = {
   email:"",
   password:"",
   createButton:"SIGN IN",
   passwordShown:false,
   isActive:false,
  }


  componentWillMount(){
    const auth = getAuth()
    onAuthStateChanged(auth, (user) =>{
   if(user != null){
     window.location.href = "/admin/dashboard"
   }
  })

  }

  handleChange = (e)=>{
    this.setState({
     [e.target.id]:e.target.value
    })
  }

  togglePassword = () =>{
    this.setState({
      passwordShown : !this.state.passwordShown
    })
  }

  signup = () =>{
    this.props.history.push('/signup')
  }

  forgotPassword = () =>{
    this.props.history.push('/forgotpassword')
  }

 handleSubmit = async(e)=>{
     e.preventDefault()
    this.setState({isActive:true})
    this.setState({createButton:"Loading..."})
    e.preventDefault()
     if(this.state.firstName === ''){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Please enter your first name",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      }); 
      this.setState({isActive:false})
      this.setState({createButton:"Sign In"})
     }
     else if(this.state.lastName === ''){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Please enter your last name",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      }); 
      this.setState({isActive:false})
      this.setState({createButton:"Sign In"})
     }
     else if(this.state.email === ''){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Please enter your email",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      }); 
      this.setState({isActive:false})
      this.setState({createButton:"Sign In"})
     }
     else if(this.state.password === ''){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Please enter your password",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      }); 
      this.setState({isActive:false})
      this.setState({createButton:"Sign In"})
     }
  
   else{
      try{
        signInWithEmailAndPassword(auth,this.state.email,this.state.password)
        .then((response)=>{
           localStorage.setItem('fisitorKey',slugify(response.user.displayName))
           this.setState({createButton:"Sign In"})
            this.props.history.push('/admin/dashboard')
        }).catch((error)=>{
          console.log(error.message)
          Swal.fire({
              title: 'Login Not Successful',  
              text: "Your email/password is wrong",  
              icon: 'error',  
              confirmButtonColor: '#eb3f3f',   
              confirmButtonText: 'Try again'
          })
          this.setState({isActive:false})
          this.setState({createButton:"Sign In"})
        })
      } catch(error){
          alert(error.message)
          this.setState({isActive:false})
          this.setState({createButton:"Sign In"})
      }
    }
    
  }
  


  render() {
  return (
    <>
    <LoadingOverlay
  active={this.state.isActive}
  spinner
  text='Loading...'
  >
  <p>.</p>
      <div className="mx-auto mt-5 px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="mt-10 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-3xl bg-blue-50 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h2 style={{fontSize:"20px"}} className="text-red-500 text-sm font-bold">
                    Login to your Account
                  </h2>
                </div>
                <div className="btn-wrapper text-center">
                  {/* <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/github.svg").default}
                    />
                    Github
                  </button> */}
                  {/* <button
                    className="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button"
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src={require("assets/img/google.svg").default}
                    />
                    Google
                  </button> */}
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                
               
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    
                    <input
                      id="email"
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      onChange={this.handleChange}
                    />
                   
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                  
                    <input
                      id="password"
                      type={this.state.passwordShown ? "text" : "password"}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      onChange={this.handleChange}
                    />
                      {this.state.passwordShown && <EyeOffIcon onClick={this.togglePassword} style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      }
                       {!this.state.passwordShown && <EyeIcon onClick={this.togglePassword} style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      } 

                  </div>
                  {/* <div className="flex flex-wrap mt-6 relative">
                  <div className="w-1/2 text-right justify-end">
                <Link
                  to="/forgotpassword"
                 
                  className="text-black-200"
                >
                  Forgot password?
                </Link>
              </div>
              </div> */}
                  <div>
                    <div className="flex justify-between">
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                    <Link className="text-red-500" to="/forgotpassword">Forgot Password?</Link>
                    </div>
                  </div>

                  <div className="mt-6 text-center">
                <p>Don't have an account? 
                <Link to="/signup" className="ml-1 font-bold text-red-500">
                   Create account
                </Link>
                </p>
              </div>

                  <div className="text-center mt-6">
                    <button
                      className="animate__animated animate__fadeInUp bg-red-500 text-white hover:bg-red-400 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={this.handleSubmit}
                    >
                     {this.state.createButton}
                    </button>
                  </div>
               
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              
              
            </div>
          </div>
        </div>
      </div>
      </LoadingOverlay>
    </>
  );
}
}
export default withRouter(Login)