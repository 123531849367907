import React from "react";
import {db} from "../../db"
import { doc, updateDoc,arrayRemove } from "firebase/firestore";
import imageAvatar from "./../../assets/img/user.png"

function EmployeeTable(props) {

  const deleteRow = (e) =>{
    const docRef = doc(db,"company",localStorage.getItem("fisitorKey"))
    updateDoc(docRef,{
      employees:arrayRemove(e)
    }).then(()=>{
      window.location.reload()
    })
  }

  // const checkIndex = (e) =>{
  //   console.log(e)
  //   console.log(props)
  // }


  return (
    props.employees.map((employee,i) =>(
        <tbody className="bg-white" key={i}>
          <tr className="text-gray-700">
            <td className="px-4 py-3 border">
              <div className="flex items-center text-sm">
                <div className="relative w-8 h-8 mr-3 rounded-full md:block">
                  <img
                    className="object-cover w-full h-full rounded-full"
                    src={imageAvatar}
                    alt=""
                    loading="lazy"
                  />
                  <div
                    className="absolute inset-0 rounded-full shadow-inner"
                    aria-hidden="true"
                  ></div>
                </div>
                <div>
                  <p
                    style={{ fontSize: "13px", lineHeight: "13px",marginBottom:"4px" }}
                    className="text-black"
                  >
                   {employee.name}
                  </p>
                  <p style={{fontSize:"10px",lineHeight:"13px"}} className="text-gray-600">{employee.role}</p>
                </div>
              </div>
            </td>
            <td className="px-4 py-3 text-xs font-semibold border">
              {employee.email}
            </td>
            <td className="px-4 py-3 text-xs border">{employee.phone}</td>
            <td className="px-4 py-3 text-xs border">
              <span className="px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-sm">
              
                {employee.department}
              </span>
            </td>
            <td className="px-4 py-3 text-sm border">
              {/* <button className="mr-4" onClick={()=>checkIndex(i)}>
               <i className="fas fa-pen"></i>
              </button> */}
              <button onClick={() => deleteRow(employee)}>
               <i className="fas fa-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
))
  );
}

export default EmployeeTable;
