import "tailwindcss/tailwind.css"
import { BrowserRouter,Route, Switch } from 'react-router-dom'
import Home from "./views/Landing"
import Dashboard from "./views/admin/Dashboard"
import Signup from './views/Auth/Register'
import Login from './views/Auth/Login'
import ForgotPassword from './views/Auth/ForgotPassword/Forgotpassword'
import Notifications from "./views/Notifications/Notifications"
import Employees from './views/Employees/Employees'
import Visitors from "./views/Visitors/Visitors"
import Profile from "./views/Profile/Profile"
import Schedule from "./views/Schedule/Schedule"
import CheckIn from "./views/CheckIn/CheckIn"
import CheckOut from "./views/CheckIn/CheckOut"
import Form from "./views/UserForm/Form"
import Badge from './views/CheckIn/Badge'
import Pricing from "./views/Pricing/Pricing"
import VerifyEmail from "./views/Auth/VerifyEmail"
import Maps from "./views/admin/Maps"
import Error from "./views/404/404"
import "./assets/styles/tailwind.css"
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css"



function App() {
  return (
    <BrowserRouter>
      <Switch>
       <Route exact path='/' component={Home} />
       <Route path="/signup" component={Signup} />
       <Route path="/login" component={Login} />
       <Route path="/forgotpassword" component={ForgotPassword} />
       <Route path="/admin/dashboard" component={Dashboard} />
       <Route path="/admin/notifications" component={Notifications} />
       <Route path="/admin/employees" component={Employees}/>
       <Route path="/admin/visitors" component={Visitors} />
       <Route path="/admin/profile" component={Profile} />
       <Route path="/admin/schedule" component={Schedule} />
       <Route path="/badge" component={Badge} />
       <Route path="/pricing" component={Pricing} />
       <Route path="/404" component={Error} />
       <Route path="/maps" component={Maps} />
       <Route path="/__/auth/:id" component={VerifyEmail} />
       <Route path="/:id/form" component={Form} />
       <Route path="/:id/checkout" component={CheckOut} />
       <Route path="/:id" component={CheckIn} />
       
       </Switch>
    </BrowserRouter>
    
  );
}

export default App;
