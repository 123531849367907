import React from "react";

export default function Footer() {

  function goFb(){
    window.location.href = "https://facebook.com/trotsupplies"
  }

  function goTwitter(){
    window.location.href = "https://twitter.com/trotsupplies"
  }

  function goInsta(){
    window.location.href = "https://instagram.com/trotsupplies"
  }

  function goLinkedIn(){
    window.location.href = "https://linkedin.com/company/trotsupplies"
  }

  function goYoutube(){
    window.location.href = "https://www.youtube.com/channel/UCrpdL-_6LNqtWrLIFiT0pMA"
  }


  return (
    <>
      <footer style={{background:"white"}} className="relative  pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
           
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="lg:flex text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4 mb-4">
              <h4 className="text-xl text-black ml-3">Follow us</h4>
              <div className="mt-3 lg:mb-0 mb-6">
              <button
                  className=" text-black font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  onClick={goFb}
                >
                  <i className="fab fa-facebook-f"></i>
                </button>
                <button
                  className="text-black font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  onClick={goTwitter}
                >
                  <i className="fab fa-twitter"></i>
                </button>
              
                <button
                  className="text-black font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  onClick={goInsta}
                >
                  <i className="fab fa-instagram"></i>
                </button>

                <button
                  className="text-black font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  onClick={goLinkedIn}
                >
                  <i className="fab fa-linkedin"></i>
                </button>

                <button
                  className="text-black font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  onClick={goYoutube}
                >
                  <i className="fab fa-youtube"></i>
                </button>
              </div>
            </div>

            <div className="mb-6 lg:w-1/3">
            <h4 className="text-xl text-black mb-3">Get in touch</h4>
            <p className="text-black">2nd Avenue, 22 Road, F Close, Block 1, Flat 6, Festac Town, Lagos, Nigeria.
+234 816 673 2518
info@trot.ng</p>
            </div>



            <div className="w-full lg:w-3/4 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto mb-6">
                  <span className="block text-black text-xl mb-2">
                    Quick Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-black hover:text-red-700 font-medium block pb-2 text-sm"
                        href="/signup"
                      >
                        Create an account
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-black hover:text-red-700 font-medium block pb-2 text-sm"
                        href="/login"
                      >
                        Sign in
                      </a>
                    </li>

                    <li>
                      <a
                        className="text-black hover:text-red-700 font-medium block pb-2 text-sm"
                        href="/forgotpassword"
                      >
                        Forgot Password
                      </a>
                    </li>
                  
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block text-black text-xl mb-2">
                   Important Links
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-black hover:text-red-700 font-medium block pb-2 text-sm"
                        href="https://trot.ng/privacy-policy/"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-black hover:text-red-700 font-medium block pb-2 text-sm"
                        href="https://trot.ng/terms-and-conditions"
                      >
                        Shipping Details
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-black hover:text-red-700 font-medium block pb-2 text-sm"
                        href="https://trot.ng/terms-and-conditions"
                      >
                        Terms and Condition
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="my-6 w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-black font-medium py-1">
                Copyright © {new Date().getFullYear()} Fisitor 
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
