/*eslint-disable*/
import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import NotificationDropdown from "../Dropdowns/NotificationDropdown";
import UserDropdown from "../Dropdowns/UserDropdown";
import {getAuth,signOut,onAuthStateChanged} from 'firebase/auth'
import { BellIcon, BriefcaseIcon, HomeIcon,CalendarIcon,LogoutIcon,UsersIcon } from "@heroicons/react/solid";

export default function Sidebar(props) {

  const [person,setPerson] = useState("")
  
 useEffect(() => {
  const auth = getAuth()
  onAuthStateChanged(auth, (user) =>{
   if(user == null){
     window.location.href="/admin/dashboard"
   }else{
   setPerson(user)
   }
  })
 },[])

//  useEffect(() =>{
//   if(props.user.pricingPlan === "" || props.user.expiryDate === ""){
//     window.location.href = "/pricing"
//   }
// })
  
  const logOut = (e) =>{
    e.preventDefault()
    console.log(e)
    const auth = getAuth()
    signOut(auth)
        .then(() => {
          localStorage.removeItem("fisitorKey")
          localStorage.removeItem("fisitorUserType")
          window.location.href = '/login'
        })

  }

  const [collapseShow, setCollapseShow] = React.useState("hidden");
  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler */}
          <div className="md:hidden">
          <NotificationDropdown />
          </div>
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
          >
          </button>
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <UserDropdown person={person}/>
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            
            {/* Form */}
            
             <div className="flex">
            <img src={person.photoURL} width="40px" />
            {person.displayName &&
            <div style={{marginLeft:"8px"}}>
              <p>Hello, {person.displayName}</p>
           
            <p style={{marginTop:"-7px"}}><Link to="/admin/profile" className="text-xs">Edit profile</Link></p>
            </div>
            }
            </div>
            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
            {/* Heading */}
            {/* Navigation */}

            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/dashboard") !== -1
                      ? "text-red-500 hover:text-lightred-500"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/dashboard"
                >
                  
                  <div className="flex">
                <HomeIcon className="w-6 h-6 mr-3"/> <p className="mt-1">Home</p>
                </div>
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/schedule") !== -1
                      ? "text-red-500 hover:text-lightred-500"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/schedule"
                >
                
                   <div className="flex">
                <CalendarIcon className="w-5 h-5 mr-3"/> <p className="mt-1">Your Schedule</p>
                </div>
                  
                </Link>
              </li>

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/visitors") !== -1
                      ? "text-red-500 hover:text-lightred-500"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/visitors"
                >
                 
                  <div className="flex">
                <UsersIcon className="w-5 h-5 mr-3"/> <p className="mt-1">Visitors</p>
                </div>
                  
                </Link>
              </li>

             {props.user && props.user.pricingPlan === "Business" && <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/employees") !== -1
                      ? "text-red-500 hover:text-lightred-500"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/employees"
                >
                  <div className="flex">
                <BriefcaseIcon className="w-5 h-5 mr-3"/> <p className="mt-1">Employees</p>
                </div>
                
                </Link>
              </li> }

              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/admin/notifications") !== -1
                      ? "text-red-500 hover:text-lightred-500"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/notifications"
                >
                  <div className="flex">
                <BellIcon className="w-5 h-5 mr-3"/> <p className="mt-1">Notifications</p>
                </div>
                
                </Link>
              </li>

            </ul>

            {/* Divider */}
            <hr className="my-4 md:min-w-full" />
         


           

            <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center" style={{cursor:"pointer"}}>
                <span
                  className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                  onClick={logOut}
                >
                  
                  <div>
                    <button className="flex bg-red-500 text-white rounded-full py-4 px-7">
                <LogoutIcon className="w-5 h-5 mr-3"/> <p className="font-bold">Log Out</p>
                </button>
                </div>
                </span>
              </li>
            </ul>
            
          </div>
        </div>
      </nav>
    </>
  );
}
