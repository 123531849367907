import React,{useState,useEffect} from 'react'
import { withRouter } from 'react-router';
import UserForm from './UserForm';
import {getDoc,doc} from 'firebase/firestore'
import {db} from '../../db'
import Modal from 'react-modal';
import  styles from './Styles'
import { unslugify } from 'unslugify';
import "./Styles.css";
import Swal from 'sweetalert2';


function Form(props) {

    const[user,setUser] = useState("")
    const[consent,setConsent] = useState("")
    const [modalIsOpen, setIsOpen] = React.useState(true);

    function closeModal() {
        if(consent === true){
            setIsOpen(false);
            }
         else{
            Swal.fire({  
                title: 'Consent not detected!',  
                text: "You need to indicate your consent to proceed",  
                icon: 'error',  
                confirmButtonColor: '#eb3f3f',   
                confirmButtonText: 'Ok'
              }); 
        }
       
      }


    useEffect(() => {
      getDoc(doc(db,'company', props.location.pathname.slice(1,-5)))
      .then((response)=>{
          setUser(response.data())
      })

    }, [props]);





    return (
        <div>
          <Modal
        isOpen={modalIsOpen}
        appElement={document.getElementById('root') || undefined}
        onRequestClose={closeModal}
        style={styles.customStyles}
        contentLabel="Example Modal"
      >
        <div className="mx-auto p-2 justify-between disclaimer">
        <button onClick={()=>props.history.goBack()}><i className="fas fa-arrow-left mr-2 mb-3"></i>Back</button>
          <h1 className="font-bold mb-3" style={{fontSize:"25px"}}>Welcome to {unslugify(props.location.pathname.slice(0,-5).slice(1))}'s Visitor Registration Page</h1>
        <p>Personal Information we collect here is used sole for you to access our facility and staffs.
We will need this information to contact and inform you where the need arises.
Your Personal information will not be used for any other purpose than stated {consent}
</p>

  <div style={{marginTop:"40px"}}>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="bg-black form-checkbox border-0 rounded text-blue-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        onChange={(e)=>setConsent(e.target.checked)}
                        value={consent}
                     />

                       {!user && <span className="text-red-500 ml-2 text-sm font-semibold">
                        I agree with the{" "}    
                        <a
                          href="#pablo"
                          style={{color:user.color}}
                          onClick={(e) => e.preventDefault()}
                        >
                          Privacy Policy
                        </a>
                      </span>}

                      {user && <span className="ml-2 text-sm font-semibold" style={{color:user.color}}>
                        I agree with the{" "}
                        <a
                          href="#pablo"
                          style={{color:user.color}}
                          onClick={(e) => e.preventDefault()}
                        >
                          Privacy Policy
                        </a>
                      </span>}
                    </label>
                  </div>
                  {!user && <button  onClick={closeModal} className="mt-6 bg-red-500 rounded-lg shadow-2xl p-3 text-white w-full">Continue</button>}
                  {user && <button style={{background:user.color}} onClick={closeModal} className="mt-6 rounded-lg shadow-2xl p-3 text-white w-full">Continue</button>}
        </div>
      </Modal>
          <UserForm users={user} />
        </div>
    )
}

export default withRouter(Form)

