import React,{useState,useEffect} from 'react'
import "./Pricing.css"
import PricingCard from "./PricingCard"
import { useSelector, useDispatch } from "react-redux";
import {doc,updateDoc} from "firebase/firestore";
import { db } from "../../db";
import { getUsers } from "../../redux/user";
// import Paystack from '../../components/Paystack/Paystack'




function Pricing() {
  const [price] = useState([
    {title:"Starter",amount:"100,000",price:100000.00,details:"For businesses who want to safely welcome people to their premise.",featureOne:"Unlimited visitors",featureTwo:"Host notifications",featureThree:"Legal documents",featureFour:"Unlimited devices",featureFive:""},
    {title:"Business",amount:"250,000",price:250000.00,details:"For businesses who need to oversee capacity across multiple offices.",featureOne:"Everything in Standard",featureTwo:"Customized branding",featureThree:"Visitor badge printing",featureFour:"Capacity limits",featureFive:"Directory integrations"},
    {title:"Custom",price:"",details:"For workplaces with complex security and compliance needs.",featureOne:"Everything in Business",featureTwo:"Block list and ID scanning",featureThree:"Guest Wi-Fi integrations",featureFour:"Access control integrations",featureFive:"Visitor screening integrations"},
  ])

  const { users } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if(users === "" && localStorage.getItem("fisitorKey") == null){
      console.log("users here",users)
      window.location.href = "/signup"
    } else if(users.pricingPlan !== "" && users.expiryDate - Date.now() > 604800000){
      window.location.href = "/admin/dashboard"
    }
  })

  

  function addPlan(){ 
    const docRef = doc(db, 'company', localStorage.getItem("fisitorKey"));
    updateDoc(docRef, {
      pricingPlan:"Basic",
      expiryDate:Date.now() + 2629800000,
      usedFree:true
    }).then(() => {
      window.location.href = "/admin/dashboard"
    });
  };


  return (
    <div className="pricingContainer">
    <div className="lg:ml-16 text-center mb-6">
     <h2 className="font-bold text-2xl mb-5"> Choose your Pricing Option </h2>
     {!users.usedFree && <p> To enjoy a <span className="font-bold" style={{color:"#eb3f3f"}}>1 month free trial</span> to use Fisitor,   <button className="text-red-500 underline hover:text-black" onClick={addPlan}>
         <span>Click here<i className="ml-3 smallIcon fas fa-chevron-right"></i>
         </span></button></p> }
      
      </div>

    <div className="cardFlex my-8">
      {price.map((price,i)=>(
         <div className="mb-3" key={i}>

         <PricingCard price={price} user={users}/>
         </div>
      ))}  
    </div>

    </div>
  )
}

export default Pricing