import React from 'react';
import { PaystackConsumer } from 'react-paystack';
import {db} from "../../db"
import {updateDoc, doc } from "@firebase/firestore";

  

  
 

  // you can call this function anything
  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    alert('Payment not completed')
  }

  function Paystack(props) {
    const config = {
      reference: (new Date()).getTime().toString(),
      email: props.email,
      amount: props.price*100,
      publicKey: 'pk_live_7cd581cda513336d4d07bdc0b36e9114a3d2d5b4',
  };
      const componentProps = {
          ...config,
          text: 'Paystack Button Implementation',
          onSuccess: (reference) => handleSuccess(reference),
          onClose: handleClose
      };

       // you can call this function anything
  const handleSuccess = (reference) => {
    const docRef = doc(db, 'company', localStorage.getItem("fisitorKey"));
    updateDoc(docRef, {
      pricingPlan:props.plan,
      expiryDate:Date.now() + 2629800000
    }).then(() => {
      window.location.href = "/admin/dashboard"
    });

    console.log(reference);
  };
  
    return (
        <PaystackConsumer {...componentProps} >
          {({initializePayment}) => 
          <button className=" pricingButton" onClick={() => initializePayment(handleSuccess, handleClose)}><span>Get Started<i className="ml-3 smallIcon fas fa-chevron-right"></i></span></button>}
        </PaystackConsumer>
    );
  }
  
  export default Paystack;