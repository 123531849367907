import React, { 
    useState,
    useEffect } from 'react'
import {MailOpenIcon} from "@heroicons/react/solid"

function VerifyEmail() {
 const [countDown,setCountDown] = useState(4)
  useEffect(()=>{
      setInterval(()=>{
          setCountDown(countDown-1)
      },1000)
      setTimeout(()=>{
          window.location.href="/pricing"
      },4000)
  })
  return (
    <div>

        <div className="p-7 shadow-xl mx-auto mt-12 w-8/12">
            <div className="text-center -mt-12">
                <div className="flex justify-center">
                <div className="w-12 h-12 p-3 shadow-lg rounded-full bg-red-500">
                <MailOpenIcon className="w-6 h-6 text-white"/>
                </div>
                </div>
            </div>
            <div className="text-center">
            <h2 className="mt-6 text-2xl font-bold">Your email has been verified</h2>
            <p className="mt-3">You can now sign in to your Fisitor account</p>

            <p className="mt-5 text-red-500"> Redirecting in : {countDown}</p>
            </div>
        </div>
    
    </div>
  )
}

export default VerifyEmail