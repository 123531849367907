import React, { useState,useEffect } from "react";
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import Sidebar from "../../components/Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/user";
import { BellIcon, TrashIcon } from "@heroicons/react/solid";
import LoadingOverlay from 'react-loading-overlay'
import {doc,updateDoc,arrayRemove} from 'firebase/firestore'
import Skeleton from 'react-loading-skeleton'
import {db} from "../../db"

export default function Notifications(props) {
  const [isActive,setIsActive] = useState(false)
  
  const { users } = useSelector((state) => state.users);

  const dispatch = useDispatch();

   const deleteNotification = async (e) =>{
     setIsActive(true)
    const docRef = doc(db,"company",localStorage.getItem("fisitorKey"))
    updateDoc(docRef,{
      notifications:arrayRemove(e)
    }).then(()=>{
      setIsActive(true)
      window.location.reload()
    })
  }




  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <>
    <LoadingOverlay
  active={isActive}
  spinner
  text='Loading...'
  >
  <p>.</p>
    <div className="pt-6">
      <div className="hidden lg:block">
        <div className=" grid grid-rows-2 grid-flow-col gap-1">
          <div className="row-span-3" style={{ zIndex: 0 }}>
            <Sidebar user={users}/>
          </div>
          <div className="font-poppins col-span-2">
            <div className="mt-3">
              <h1 className="font-bold text-xl">Notifications</h1>
            </div>
            <div className="px-5 mt-2">
            {!users && <Skeleton style={{width:"80%",margin:"auto"}} count={2}/>}
            </div>
            <div className="px-5 mt-2">
            {!users && <Skeleton style={{width:"80%",margin:"auto"}} count={2}/>}
            </div>
            <div className="px-5 mt-2">
            {!users && <Skeleton style={{width:"80%",margin:"auto"}} count={2}/>}
            </div>
            {users && users.notifications.map((notification,i) =>(
              <div key={i}>
                <div className="flex mt-5 mb-3">
                  <BellIcon className="w-7 h-7 rounded-full p-2 bg-red-500 text-white" />
                  <div>
                    <h1 className="pl-4 font-bold"  style={{fontSize:"19px"}}>
                    {notification.title}
                    </h1>
                    <p className="pl-4">{notification.message}</p>
                  </div>
                </div>
                <div className="flex">
                <hr style={{width:"70%"}}/> <TrashIcon onClick={()=>{deleteNotification(notification)}} style={{marginTop:"-60px"}} className="w-7 h-7 rounded-full p-2 bg-black text-white"/>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="lg:hidden xs:block">
      <button onClick={()=>props.history.goBack()} className="px-6"><i className="fas fa-arrow-left px-2"></i>Back</button>

        <div>
          <div>
            <div className="px-5">
              <h1 className="font-bold text-xl">Notifications</h1>
            </div>
            <div className="px-5 mt-2">
            {!users && <Skeleton style={{width:"100%",margin:"auto"}} count={2}/>}
            </div>
            <div className="px-5 mt-2">
            {!users && <Skeleton style={{width:"100%",margin:"auto"}} count={2}/>}
            </div>
            <div className="px-5 mt-2">
            {!users && <Skeleton style={{width:"100%",margin:"auto"}} count={2}/>}
            </div>

            {users && users.notifications.map((notification,i) =>(
              <div key={i} className="p-5">
                <div className="flex mb-3 shadow p-4">
                <BellIcon className="w- h-7 rounded-full p-2 bg-red-500 text-white" />
                  <div>
                    <h1 className="pl-4 font-bold"  style={{fontSize:"17px"}}>{notification.title}</h1>
                    <p className="pl-4" style={{fontSize:"14px"}}>{notification.message}</p>
                  </div>
                  <TrashIcon onClick={()=>{deleteNotification(notification)}} className="w-7 h-7  text-black" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <BottomMenu user={users}/>
    </div>
    </LoadingOverlay>
    </>
  );
}
