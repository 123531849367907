import React from "react";
import {Link} from "react-router-dom"

const NotificationDropdown = () => {
  return (
    <>
    <div style={{float:"left"}}>
      <Link to="/admin/notifications">
        <i className="fas fa-bell"></i>
      </Link>
      </div>
    </>
  );
};

export default NotificationDropdown;
