import { BriefcaseIcon, CalendarIcon, HomeIcon, UsersIcon } from "@heroicons/react/solid"
import React from "react"
import {Link} from 'react-router-dom'


export default function BottomMenu(props){
    return(
        <nav style={{borderRadius:"30px 30px 0px 0px"}} className="xl:hidden lg:hidden fixed bottom-0 inset-x-0 bg-gray-100 py-3 flex justify-between text-sm text-black-900">
            <Link className="block p-4 py-5 px-3 text-center w-full" to="/admin/dashboard">
                <HomeIcon style={{color:"black"}} className="text-center w-full w-6 h-6" />
                Home
            </Link >
            <Link className="block p-4 py-5 px-3 text-center w-full" to="/admin/visitors">
                <UsersIcon style={{color:"black"}} className="text-center w-full w-6 h-6"/>
                Visitors
            </Link>
            {props.user && props.user.pricingPlan === "Business" && <Link className="text-center w-full block p-4  py-5 px-3 text-center w-full" to="/admin/employees">
                <BriefcaseIcon style={{color:"black"}} className="text-center w-full w-6 h-6" />
                Employees
            </Link>}
            <Link className="block p-4  py-5 px-3 text-center w-full" to="/admin/schedule">
                <CalendarIcon style={{color:"black"}} className="text-center w-full w-6 h-6" />
                Schedule
            </Link>
        </nav>
    )
}