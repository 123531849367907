import React,{useState} from 'react'


function ShareLink() {
    const[linkShare] = useState(window.location.origin+"/"+localStorage.getItem('fisitorKey'))
    const facebookShare = () =>{
     
    }

    const twitterShare = () =>{

    }

    const linkedInShare = () =>{

    }

    const whatsappShare = () =>{

    }

    return (
        <div className="p-3">
            <a target="noreferrer _blank" href={"https://facebook.com/sharer/sharer.php?u="+linkShare}>
            <button className="animate__animated animate__fadeInUp animate__faster mb-3 mt-4 justify-center w-full h-12 text-white flex shadow bg-blue-500 p-3 rounded-lg" onClick={facebookShare}>
                <span className="text-center">
                <i className="fab fa-facebook mt-1 mr-3"></i>
                Share on Facebook
                </span>
                </button>
                </a>
               
                <a target="noreferrer _blank" href={"https://twitter.com/intent/tweet?url="+linkShare}>
                <button className="animate__animated animate__fadeInUp animate__fast mt-4 justify-center w-full text-white flex shadow bg-blue-400 p-3 rounded-lg" onClick={twitterShare}>
                <i className="fab fa-twitter mt-1 mr-3"></i>
                Share on Twitter</button>
                </a>
                <a target="noreferrer _blank" href={"https://www.linkedin.com/shareArticle?mini=true&url="+linkShare}>
                <button className="animate__animated animate__fadeInUp animate__slow mt-4 justify-center w-full text-white flex shadow bg-blue-600 p-3 rounded-lg" onClick={linkedInShare}>
                <i className="fab fa-linkedin mt-1 mr-3"></i>
                Share on LinkedIn</button>
                </a>
                <a target="noreferrer _blank" href={"https://wa.me/?text="+linkShare}>
                <button className="animate__animated animate__fadeInUp animate__slower mt-4 justify-center w-full text-white flex shadow bg-green-500 p-3 rounded-lg" onClick={whatsappShare}>
                <i className="fab fa-whatsapp mt-1 mr-3"></i>
                Share on WhatsApp</button>
                </a>
        </div>
    )
}

export default ShareLink
