import React, { useState, useEffect } from "react";
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import Sidebar from "../../components/Sidebar/Sidebar";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { getAuth, updateProfile } from "firebase/auth";
import { updateDoc,doc} from "@firebase/firestore";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../redux/user";
import "./Profile.css";
import Swal from "sweetalert2";
import {db} from "../../db"
import { LocationMarkerIcon} from "@heroicons/react/solid";

function Visitors(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [image, setImage] = useState("");
  const [address, setAddress] = useState("");
  const [color,setColor] = useState("")
  const [uploadImage, setUploadImage] = useState("");
  const [imageData, setImageData] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [submitButton, setSubmitButton] = useState('Submit')



  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubmitButton("Loading...")
    setIsActive(true)
    const docRef = doc(db,'company', localStorage.getItem("fisitorKey"));
    await updateDoc(docRef,{
        firstName:firstName,
        lastName:lastName,
        email:email,
        phoneNumber:phoneNumber,
        address:address,
        color:color
    })
    setIsActive(false)
    Swal.fire({  
      title: 'Profile Updated!',  
      text: "You have successfully updated your Fisitor profile",  
      icon: 'success',  
      confirmButtonColor: color,   
      confirmButtonText: 'Ok'
    });
    setSubmitButton('Submit')
  };

  const changePicture = (e) => {
    e.preventDefault();
    const imageFile = document.getElementById("image");
    imageFile.click();
  };

  const onFileChanged = (event) => {
    var input = event.target;
    if (input.files) {
      var reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target.result);
      };
      setUploadImage(event.target.files);
      reader.readAsDataURL(input.files[0]);
    }
    setIsActive(true)
    console.log(event.target.files[0].name)
    setImageData("Mr Miksy")
    console.log("this is image data"+imageData);
    console.log("this is uploaded image"+uploadImage)
    const storage = getStorage();
    // Create the file metadata
    /** @type {any} */
    const metadata = {
      contentType: "image/jpeg",
    };
    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, "images/" + event.target.files[0].name);
    const uploadTask = uploadBytesResumable(storageRef, event.target.files[0], metadata);
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          default:
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;
          // ...
          default:
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const auth = getAuth();
          updateProfile(auth.currentUser, {
            photoURL: downloadURL,
          })
            .then(() => {
              console.log("this is download link",downloadURL)
              changeImage(downloadURL);
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                title: "Something went wrong 😥",
                text: "Sorry, we couldn't update your profile picture. It could be a network issue",
                icon: "error",
                confirmButtonText: "Yay!",
              });
            });
        });
      }
    );
  };


    const changeImage = async(e) => {
    console.log("this is the image link:" + e)
    await updateDoc(doc(db, "company", localStorage.getItem("fisitorKey")), {
      image: e,
    });
    setIsActive(false)
    Swal.fire({
      title: "Profile picture Updated!",
      text: "Good job!. You've successfully updated your profile!",
      icon: "success",
      confirmButtonText: "Yay!",
    });
  }



  const { users } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
    setFirstName(users.firstName);
    setLastName(users.lastName);
    setEmail(users.email);
    setPhoneNumber(users.phoneNumber);
    setImage(users.image);
    setColor(users.color)
    setAddress(users.address)
  }, [
    dispatch,
    users.firstName,
    users.lastName,
    users.email,
    users.phoneNumber,
    users.image,
    users.color,
    users.address

  ]);

  return (
    <LoadingOverlay active={isActive} spinner text="Loading...">
      <p>.</p>
      <>
        {/* Laptop view */}
        <div className="hidden lg:block">
          <div className=" grid grid-rows-3 grid-flow-col gap-4">
            <div
              className="hidden lg:block xl:block 2xl:block row-span-3"
              style={{ zIndex: 0 }}
            >
              <Sidebar user={users} />
            </div>
            <div className="font-poppins col-span-2">
              <div className="p-6 justify-between ">
                <h1 className="font-bold text-xl">Profile</h1>

                <div className="mt-5" style={{ margin: "50px 190px 0px 0px" }}>
                  <div className="flex mb-8">
                    <img
                      alt="profilePhoto"
                      height="70px"
                      width="70px"
                      src={image}
                     
                    />
                    <input
                      hidden
                      type="file"
                      id="image"
                      accept="image/*"
                      onChange={onFileChanged}
                    />
                    <button
                      onClick={changePicture}
                      style={{ width: "120px" }}
                      className="ml-2 h-12 text-xs shadow border rounded-lg border-blue"
                    >
                      Change Logo
                    </button>
                  </div>

                {/* form body */}
                  <form onSubmit={handleSubmit}>
                    <div className="flex relative w-full mb-9">
                      <div className="w-1/2 h-12 mr-4">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          First Name
                        </label>
                        <input
                          id="firstName"
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Your First Name"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-1/2 h-12">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Last Name
                        </label>
                        <input
                          id="lastName"
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Your Last Name"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          value={lastName}
                        />
                      </div>
                    </div>

                    {/* <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                     Company Name
                      </label>

                    <input
                      id="companyName"
                      type="companyName"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Company Name"
                      onChange = {handleChange}
                      value={firstName}
                    />
                   
                  </div> */}

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>

                      <input
                        id="email"
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Phone Number
                      </label>

                      <input
                        id="phoneNumber"
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        value={phoneNumber}
                      />
                    </div>

                    {users.pricingPlan ==="Business" &&
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Brand color
                      </label>

                      <input
                        id="primary_color"
                        type="color"
                        className="rounded-full"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          setColor(e.target.value)
                        }}
                        value={color}
                      />
                    </div>
                    }

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Address
                      </label>
                      <div className="flex">
                        <LocationMarkerIcon className="w-7 h-7 mt-2 mr-1"/>
                      <input
                        id="address"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Address"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        value={address}
                      />
                      </div>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-red-500 text-white hover:bg-blue-900 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleSubmit}
                      >
                       {submitButton}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className="lg:hidden xs:block" style={{ marginBottom: "300px" }}>
        <button onClick={()=>props.history.goBack()} className="px-6"><i className="fas fa-arrow-left px-2"></i>Back</button>
          <div className="flex p-6 justify-between ">
            <h1 className="font-bold text-xl">Profile</h1>
            {/* <button className="px-4 py-3 bg-red-500 rounded-md text-white outline-none focus:ring-4 shadow-lg transform active:scale-x-75 transition-transform mx-5 flex">
              <i className="fas fa-plus h-2 m-1 mr-5 w-2"></i> Add Schedule
            </button> */}
          </div>
          <div className="mx-6 mb-8 overflow-hidden rounded-lg">
          <form onSubmit={handleSubmit}>
                    <div className="flex relative w-full mb-9">
                      <div className="w-1/2 h-12 mr-4">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          First Name
                        </label>
                        <input
                          id="firstName"
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Your First Name"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-1/2 h-12">
                        <label
                          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Last Name
                        </label>
                        <input
                          id="lastName"
                          type="text"
                          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Your Last Name"
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                          value={lastName}
                        />
                      </div>
                    </div>

                    {/* <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                     Company Name
                      </label>

                    <input
                      id="companyName"
                      type="companyName"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Company Name"
                      onChange = {handleChange}
                      value={firstName}
                    />
                   
                  </div> */}

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>

                      <input
                        id="email"
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        value={email}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Phone Number
                      </label>

                      <input
                        id="phoneNumber"
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        value={phoneNumber}
                      />
                    </div>

                    
                    {users.pricingPlan ==="Business" &&
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Brand color
                      </label>

                      <input
                        id="primary_color"
                        type="color"
                        className="rounded-full"
                        placeholder="Phone Number"
                        onChange={(e) => {
                          setColor(e.target.value)
                        }}
                        value={color}
                      />
                    </div>}

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Address
                      </label>
                      <div className="flex">
                        <LocationMarkerIcon className="w-7 h-7 mt-2 mr-1"/>
                      <input
                        id="address"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Address"
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                        value={address}
                      />
                      </div>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-red-500 text-white hover:bg-blue-900 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleSubmit}
                      >
                       {submitButton}
                      </button>
                    </div>
                  </form>
          </div>
        </div>
        <div>
          <BottomMenu user={users}/>
        </div>
      </>
    </LoadingOverlay>
  );
}

export default Visitors;
