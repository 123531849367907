import React, { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../db";
import { unslugify } from "unslugify";
import { Link } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

function CheckIn(props) {
  const [user, setUser] = useState("");
  const [isActive,setActive] = useState(true)

  useEffect(() => {
    getDoc(doc(db, "company", props.location.pathname)).then((response) => {
      if(response.data()===undefined){
       props.history.push('/404')
      }
      else{
        setUser(response.data());
        setActive(false)
      }
    });
  }, [props]);

  return (
    <LoadingOverlay
    active={isActive}
      spinner
      text="Please wait..."
      style={{ height: "100px" }}
      className="pb-20"
      wrapper
    >
      <p>.</p>
    <div>
      <div className="container mt-5 mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="mt-12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-3xl sborder-0">
              <img src={user.image} alt="" className="avatar" />
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h1
                    style={{
                      fontSize: "20px",
                    }}
                    className="font-bold"
                  >
                    Welcome to <span style={{color: user.color}}>{unslugify(props.location.pathname.slice(1))}'s </span>
                    Visitor Check-in page
                  </h1>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              { user &&
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <Link to={props.location.pathname + "/form"}>
                  <button
                    style={{ background: user.color }}
                    className="animate__animated animate__fadeInUp animate__fast mb-5 flex justify-between w-full p-4 rounded-xl text-white"
                  >
                    <span>Check-in for a visit</span>
                    <span className="align-end">
                      <i className="fas fa-door-open"></i>
                    </span>
                  </button>
                </Link>

                <Link to={props.location.pathname + "/checkout"}>
                  <button
                    style={{ borderColor: user.color, color: user.color }}
                    className="animate__animated animate__fadeInUp animate__slow border flex justify-between w-full p-4 rounded-xl"
                  >
                     
                    <span>Check-out from a visit</span>
                    <span className="align-end">
                      <i className="fas fa-sign-out-alt"></i>
                    </span>
                  </button>
                </Link>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    </LoadingOverlay>
  );
}

export default CheckIn;
