import React, {Component} from "react";
import "tailwindcss/tailwind.css"
import {auth} from '../../../firebase-auth'
import Swal from "sweetalert2";  
import { withRouter } from 'react-router-dom';
import {sendPasswordResetEmail } from "firebase/auth";



export class Signup extends Component {
  state = {
   email:"",
   password:"",
   createButton:"Send reset email"
  }

  handleChange = (e)=>{
    this.setState({
     [e.target.id]:e.target.value
    })
  }

  goBack = () =>{
    this.props.history.goBack(-1)
  }

  signup = () =>{
    this.props.history.push('/signup')
  }

 handleSubmit = async (e)=>{
  this.setState({createButton:"Loading..."}) 
  e.preventDefault()
  sendPasswordResetEmail(auth,this.state.email)
  .then(()=>{
      Swal.fire({
        title: 'Email sent successfully',  
        text: "Kindly check your inbox/spam for an email from us",  
        icon: 'success',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      this.setState({createButton:"Send reset email"}) 
  }).catch((error)=>{
      console.log(error)
    Swal.fire({
        title: 'Password not reset',  
        text: "This email is not associated with any account on Fisitor",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Try again'
      })
      this.setState({createButton:"Send reset email"})
  })
  
  }


  render() {
    return (
      <div>
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <span style={{cursor:"pointer"}} onClick={this.goBack}>
            <i className="fas fa-arrow-left mr-3"></i>
            Back
            </span>
          <div>
            <h2 className="mt-9 text-center text-3xl font-extrabold text-red-500">Did you forget your password?</h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              <a href="/" className="font-medium text-red-500 hover:text-blue-500">
                Enter your email to reset your password
              </a>
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={this.handleSumbit}>
            
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange = {this.handleChange}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Enter your email"
                />
              </div>
            </div>

            <div>
              <button
                className="group relative h-full w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={this.handleSubmit}
             >
                {this.state.createButton}
              </button>
            </div>
          </form>
        </div>
      </div>
      </div>
    )
  }
}

export default withRouter(Signup)
