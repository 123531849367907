import React from 'react'
import image from './../../assets/img/error.jpg'
import {Link} from "react-router-dom"

function error() {
    return (
        <div>
            <button onClick={()=>window.location.href = "/"}>
            <img src={image} alt="404"/>
            <div style={{position:"fixed",top:"0",left:"0",right:"0",bottom:"0"}} className="text-center">
           <Link to="/" className="bg-red-500 p-4 text-white rounded-lg">Go Home</Link>
           </div>
           </button>
        </div>
    )
}

export default error
