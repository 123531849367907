import React from "react";
import moment from 'moment'
import { ClockIcon, TrashIcon } from "@heroicons/react/solid";
import ScheduleMessage from "./ScheduleMessage";
import { doc, updateDoc,arrayRemove } from "firebase/firestore";
import { db } from "../../db";
import LoadingOverlay from "react-loading-overlay";

function TabsRender(props) {
  const[color] = React.useState("red")
  const [openTab, setOpenTab] = React.useState(1);
  const [isActive,setisActive] = React.useState(false)

   const deleteSchedule = (e) =>{
    setisActive(true)
    const docRef = doc(db, "company", localStorage.getItem("fisitorKey"));
    updateDoc(docRef, {
      schedule: arrayRemove(e),
    }).then(() => {
       setisActive(false)
      window.location.reload();
    });
  }
 
  return (
    <LoadingOverlay active={isActive} spinner text="Loading...">
    <p>.</p>
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-" + color + "-400"
                    : "text-" + color + "-400 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1)
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Today
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-" + color + "-500"
                    : "text-" + color + "-500 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                 Upcoming
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                  (openTab === 3
                    ? "text-white bg-" + color + "-500"
                    : "text-" + color + "-500 bg-white")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                 Past
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
           
              {props.users && props.users.schedule.filter(schedule => moment(schedule.timestamp.seconds*1000).format('LL') === moment(Date.now()).format('LL') && schedule.timestamp.seconds*1000 > Date.now() ).map((schedule,i)=>(
            <div className={openTab === 1 ? "block" : "hidden"} id="link1" key={i}>
              <div className="flex shadow px-3 py-5 mb-4">
                  <ClockIcon className="hidden lg:block xl:block 2xl:block w-7 h-7 mr-3 p-2 bg-red-500 text-white rounded-full"/>
                  {/* Mobile's icon */}
                  <ClockIcon className="xl:hidden lg:hidden w-8 h-8 mr-3 p-2 bg-red-500 rounded-full text-white "/>
               <div className="mb-2">
                <b>{schedule.name}</b> will be coming to the office
                <br className="xl:hidden lg:hidden" />  <br className="xl:hidden lg:hidden" />
                <span style={{fontSize:"14px"}} className="shadow-lg rounded-lg bg-red-500 text-white px-3 p-2 ml-2">
                    {moment(parseFloat(schedule.timestamp.seconds)*1000).format("LLL")}
                    </span>
                    <br /><br/>
                    <b>Whom to see: </b>{schedule.whotosee}
                    <br />
                    <br/>
                    <div className="flex">
                    <ScheduleMessage data={schedule} /> <TrashIcon className="text-red-500 w-6 h-6 mt-2 mr-2" onClick={() =>deleteSchedule(schedule)} />
                    </div>
               </div>
               </div>
             </div>
               ))
            }
            
            {props.users && props.users.schedule.filter(schedule => schedule.timestamp.seconds*1000 > Date.now()).map((schedule,i)=>(
            <div className={openTab === 2 ? "block" : "hidden"} id="link1" key={i}>
              <div className="flex shadow px-3 py-5 mb-4">
                  <ClockIcon className="hidden lg:block xl:block 2xl:block w-7 h-7 mr-3 p-2 bg-red-500 text-white rounded-full"/>
                  {/* Mobile's icon */}
                  <ClockIcon className="xl:hidden lg:hidden w-8 h-8 mr-3 p-2 bg-red-500 rounded-full text-white "/>
               <div className="mb-2">
                <b>{schedule.name}</b> will be coming to the office
                <br className="xl:hidden lg:hidden" />  <br className="xl:hidden lg:hidden" />
                <span style={{fontSize:"14px"}} className="shadow-lg rounded-lg bg-red-500 text-white px-3 p-2 ml-2">
                    {moment(parseFloat(schedule.timestamp.seconds)*1000).format("LLL")}
                    </span>
                    <br /><br/>
                    <b>Whom to see: </b>{schedule.whotosee}
                    <br />
                    <br/>
                    <div className="flex">
                    <ScheduleMessage data={schedule} /> 
                    <TrashIcon className="text-red-500 w-6 h-6 mt-2 mr-2" onClick={() =>deleteSchedule(schedule)} />
                    </div>
               </div>
               </div>
             </div>
               ))
            }
              
              {props.users && props.users.schedule.filter(schedule =>  schedule.timestamp.seconds*1000 < Date.now()).map((schedule,i)=>(
            <div className={openTab === 3 ? "block" : "hidden"} id="link1" key={i}>
              <div className="flex shadow px-3 py-5 mb-4">
                  <ClockIcon className="hidden lg:block xl:block 2xl:block w-7 h-7 mr-3 p-2 bg-red-500 text-white rounded-full"/>
                  {/* Mobile's icon */}
                  <ClockIcon className="xl:hidden lg:hidden w-8 h-8 mr-3 p-2 bg-red-500 rounded-full text-white "/>
               <div className="mb-2">
                <b>{schedule.name}</b> will be coming to the office
                <br className="xl:hidden lg:hidden" />  <br className="xl:hidden lg:hidden" />
                <span style={{fontSize:"14px"}} className="shadow-lg rounded-lg bg-red-500 text-white px-3 p-2 ml-2">
                    {moment(parseFloat(schedule.timestamp.seconds)*1000).format("LLL")}
                    </span>
                    <br /><br/>
                    <b>Whom to see: </b>{schedule.whotosee}
                    <br />
                    <br/>
                    <div className="flex">
                    <ScheduleMessage data={schedule} /> 
                    <TrashIcon className="text-red-500 w-6 h-6 mt-2 mr-2" onClick={() =>deleteSchedule(schedule)} />
                    </div>
               </div>
               </div>
             </div>
               ))
            }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    </LoadingOverlay>
  );
};

export default TabsRender