import React from 'react'
import {CheckIcon} from "@heroicons/react/solid"
import "./Pricing.css"
import Paystack from '../../components/Paystack/Paystack'

function PricingCard(props) {
  return (
    <div>
       <div className="text-center lg:ml-6 rounded-lg shadow-lg p-3">
            <p className="pt-4">{props.price.title}</p>
            <p className="text-4xl font-medium" style={{color:props.price.price !== "" ? "black":"white"}}>₦ {props.price.amount}</p>
            <p className="mt-1 mb-2 text-md">per location/annum</p>
            <div className="my-3">
            <hr style={{height:"3px",margin:"auto",width:"30px",background:"#eb3f3f"}}/>
            </div>
            <p className="text-center pricingText">{props.price.details}</p>
          
           <div className="mt-3">
           <ul className="features">
                <li><p className="flex justify-center">
                    <CheckIcon className="bg-green-400 text-white  shadow-lg p-1 rounded-full mr-2" style={{position:"relative",height:"20px",width:"20px"}} /> <span style={{marginTop:"-3px"}}>{props.price.featureOne} </span></p>
                    </li>

                    <li><p className="flex justify-center">
                    <CheckIcon className="bg-green-400 text-white  shadow-lg p-1 rounded-full mr-2" style={{position:"relative",height:"20px",width:"20px"}} /> <span style={{marginTop:"-3px"}}>{props.price.featureTwo} </span></p>
                    </li>

                    <li><p className="flex justify-center">
                    <CheckIcon className="bg-green-400 text-white  shadow-lg p-1 rounded-full mr-2" style={{position:"relative",height:"20px",width:"20px"}} /> <span style={{marginTop:"-3px"}}>{props.price.featureThree} </span></p>
                    </li>

                    <li><p className="flex justify-center">
                    <CheckIcon className="ml-1 bg-green-400 text-white  shadow-lg p-1 rounded-full" style={{position:"relative",height:"20px",width:"20px"}} /> <span className="ml-2" style={{marginTop:"-3px"}}>{props.price.featureFour} </span></p>
                    </li>

                    <li style={{color:props.price.featureFive !== "" ? "black":"white"}}><p className="flex justify-center">
                    <CheckIcon className="ml-1 bg-green-400 text-white  shadow-lg p-1 rounded-full" style={{background:props.price.featureFive !== "" ? "#34d399":"white",position:"relative",height:"20px",width:"20px"}} /> <span className="ml-2" style={{marginTop:"-3px"}}>{props.price.featureFive} </span></p>
                    </li>
            
            </ul>

            {props.price.title !=="Custom" && <div className="buttonContainer">
               <Paystack price={props.price.price} email={props.user.email} plan={props.price.title} />
            </div>}

            {props.price.title ==="Custom" && <div className="buttonContainer">
               <button className=" pricingButton">Coming soon</button>
            </div>}
            
            </div>
        </div>
    </div>
  )
}

export default PricingCard