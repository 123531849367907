import React, { useEffect, useState } from "react";
import {
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../db";
import { unslugify } from "unslugify";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";

function CheckOut(props) {
  const [user, setUser] = useState("");
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    getDoc(doc(db, "company", props.location.pathname.slice(1, -9))).then(
      (response) => {
        if (response.data() === undefined) {
          props.history.push("/404");
        } else {
          setUser(response.data());
        }
      }
    );
  }, [props]);

  const goBack = (e) =>{
    e.preventDefault()
    props.history.goBack()
  }

  const checkUserOut = (e) => {
    setActive(true);
    const docRef = doc(db, "company", props.location.pathname.slice(1, -9));
    updateDoc(docRef, {
      visitors: arrayRemove(e),
    }).then(() => {
      e.status = "checked out";
      updateDoc(docRef, {
        visitors: arrayUnion(e),
      }).then(() => {
        Swal.fire({
          title: "Check-out successful",
          text:
            e.name +
            " has been checked out successfully. Do visit us again sometime",
          icon: "success",
          confirmButtonColor: "#eb3f3f",
          confirmButtonText: "Ok",
        });
        setActive(false);
      });
    });
  };

  return (
    <LoadingOverlay
      active={isActive}
      spinner
      text="Loading..."
      style={{ height: "100px" }}
      className="pb-20"
      height="100px"
      wrapper
    >
      <p>.</p>
      <div>
        <div className="container mt-5 mx-auto px-4 h-full">
        <button onClick={goBack}><i className="fas fa-arrow-left ml-2"></i> Back</button>
          <div className="flex content-center items-center justify-center h-full">
           
            <div className="w-full lg:w-6/12 px-4">
              <div className="mt-12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-3xl sborder-0">
                <img src={user.image} alt="" className="avatar" />
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h1
                      style={{
                        fontSize: "20px",
                      }}
                      className="font-bold"
                    >
                      List of checked-in visitors at{" "}
                      <span style={{ color: user.color }}>
                        {unslugify(props.location.pathname.slice(1, -9))}'s{" "}
                      </span>
                      Office
                    </h1>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="mx-6">
                  {user &&
                    user.visitors
                      .filter((visitor) => visitor.status === "checked in").reverse()
                      .map((visitor, i) => (
                        <div
                          className="shadow-lg p-7 rounded-lg mb-4 flex justify-between"
                          key={i}
                        >
                          <div className="lg:flex">
                            <div className="relative w-12 h-12 mr-3 rounded-full md:block">
                              <img
                                className="object-cover w-full h-full rounded-full"
                                src={visitor.image}
                                alt=""
                                loading="lazy"
                              />
                              <div
                                className="absolute inset-0 rounded-full shadow-inner"
                                aria-hidden="true"
                              ></div>
                            </div>
                            <p>
                              {visitor.name}
                              <br />
                              <span
                                style={{ fontSize: "12px" }}
                                className="text-green-500 border-green-500 border rounded-full p-1 px-3"
                              >
                                {visitor.status}
                              </span>
                            </p>
                          </div>
                          <button
                            onClick={() => checkUserOut(visitor)}
                            style={{background:user.color}}
                            className="text-sm hover:shadow-lg p-3 rounded-lg text-white"
                          >
                            check out <i className="fas fa-sign-out-alt"></i>
                          </button>
                        </div>
                      ))}

                  {!user && (
                    <p className="text-center p-3">
                      No visitors have been signed in
                    </p>
                  )}
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default CheckOut;
