import React, { useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import HeaderStats from "../../components/Headers/HeaderStats";
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import { useSelector, useDispatch } from "react-redux";
import { getUsers } from "../../redux/user";
import {
  ClipboardCopyIcon,
  PencilAltIcon,
  ShareIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { XIcon } from "@heroicons/react/solid";
import ShareLink from "./ShareLink";
import ShareLinkMobile from "./ShareLinkMobile";
import Swal from "sweetalert2";

export default function Dashboard() {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(52, 52, 52, 0.8)",
    },

    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      width: "40%",
      height: "500px",
      transform: "translate(-50%, -50%)",
      zIndex: 20,
    },
  };

  const customMobileStyles = {
    overlay: {
      backgroundColor: "rgba(52, 52, 52, 0.8)",
    },

    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      width: "90%",
      height: "500px",
      transform: "translate(-50%, -50%)",
      zIndex: 20,
    },
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalMobileIsOpen, setMobileOpen] = React.useState(false);
  const [visitorLink] = React.useState(window.location.origin +"/"+ localStorage.getItem("fisitorKey"))

  function openModal() {
    setIsOpen(true);
  }

  function openMobileModal() {
    setMobileOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeMobileModal() {
    setMobileOpen(false);
  }

  // function closedeleteModal() {
  //   setdeleteModal(false);
  // }

  const copyClipboard = () => {
    navigator.clipboard.writeText(
      window.location.origin +"/"+ localStorage.getItem("fisitorKey")
    );

    /* Alert the copied text */
    alert("Visitor Link Copied to clipboard");
  };

  const { users } = useSelector((state) => state.users);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(()=>{
    const date = new Date(users.expiryDate);
    if(users.expiryDate - Date.now() < 604800000 && users.expiryDate - Date.now() > 0 ){
      Swal.fire({
         title: 'Heads Up '+users.firstName+"!",
         text: "Your subscription on Fisitor will expire on " + date + ". Tap the button below to renew your subscription",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#eb3f3f',
         cancelButtonColor: 'black',
         confirmButtonText: 'Renew Subscription'
      }).then((result)=>{
        if (result.isConfirmed){
          window.location.href = "/pricing"
        }

      })
    } else if(users.expiryDate < Date.now()){
      Swal.fire({
        title: 'Your subscription has expired!',
        text: "Sorry "+users.firstName+ " your subscription on Fisitor has expired. Kindly tap the button below to renew your subscription",
        icon: 'warning',
        confirmButtonColor: '#eb3f3f',
        confirmButtonText: 'Renew Subscription'
     }).then(()=>{
         window.location.href = "/pricing"
     })
    }
  })

 

  return (
    <>
      <div style={{ zIndex: 0 }}>
        <Sidebar user={users} />
      </div>
      <div>
        <div
          style={{ backgroundColor: "#0284c7", paddingBottom: "500px" }}
          className="lg:hidden xs:block"
        >
          <HeaderStats users={users} />
          <div style={{ paddingTop: "-600px",zIndex:0 }} className="mt-16">
            <div
              style={{ width: "300px", padding: "50px" }}
              className="mx-8 rounded-lg bg-white shadow-xl"
            >
              <h1 className="mb-2 text-xl font-bold">
                Share your Visitor Link
              </h1>
              <Link style={{textDecoration:"underline"}} className="text-red-500 mb-3" to={"/"+ localStorage.getItem("fisitorKey")}>{visitorLink}</Link>
              <p className="text-sm mt-3">
                Manage your visitor registrations and get vistors to schedule
                meetings in minutes
              </p>
              <div className="flex">
                <button
                  onClick={copyClipboard}
                  className="flex hover:shadow-2xl hover:bg-black shadow-xl rounded-lg bg-red-500 p-3 mt-3 text-white text-xs"
                >
                  <ClipboardCopyIcon className="w-4 h-4 mr-2" /> Copy Clipboard
                </button>
                <button
                  onClick={openMobileModal}
                  className="hover:bg-black ml-3 rounded-full bg-orange-500 text-white p-4 mt-2"
                >
                  <ShareIcon className="w-4 h-4" />{" "}
                </button>
              </div>
            </div>

            <div
              style={{ width: "300px", padding: "50px" }}
              className="rounded-lg bg-white mr-8 ml-8 mt-5 shadow-xl"
            >
              <h1 className="mb-2 text-xl font-bold">
                Customize your Visitor Link
              </h1>
              <p className="text-sm w-15">
                Personalize your Visitor page and customize it to be more
                appealing to visitors
              </p>
              <Link to="/admin/profile">
                <div className="flex">
                  <button className="flex hover:shadow-2xl hover:bg-black shadow-xl rounded-lg bg-red-500 p-3 mt-3 text-white text-sm">
                    <PencilAltIcon className="mt-2 w-4 h-4 mr-2" /> Customize
                    page
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="hidden lg:block" style={{ marginLeft: "270px" }}>
          <HeaderStats users={users} />
          <div className="flex">
            <div
              style={{ width: "500px", padding: "50px" }}
              className="mt-5 shadow-xl"
            >
              <h1 className="mb-2 text-xl font-bold">
                Share your Visitor Link
              </h1>
              <Link style={{textDecoration:"underline"}} className="text-red-500 mb-3" to={"/"+ localStorage.getItem("fisitorKey")}>{visitorLink}</Link>
              <p className="text-sm mt-3">
                Manage your visitor registrations and get vistors to schedule
                meetings in minutes
              </p>
              <div className="flex">
                <button
                  onClick={copyClipboard}
                  className="flex hover:shadow-2xl hover:bg-black shadow-xl rounded-lg bg-red-500 p-3 mt-3 text-white "
                >
                  <ClipboardCopyIcon className="w-5 h-5 mr-2" /> Copy link to
                  clipboard
                </button>
                <button
                  onClick={openModal}
                  className="hover:bg-black ml-3 rounded-full bg-orange-500 text-white p-5"
                >
                  <ShareIcon className="w-5 h-5" />{" "}
                </button>
              </div>
            </div>

            <div
              style={{ width: "500px", padding: "50px" }}
              className="ml-6 mt-5 shadow-xl"
            >
              <h1 className="mb-2 text-xl font-bold">
                Customize your Visitor Link
              </h1>
              <p className="text-sm w-15">
                Personalize your Visitor page and customize it to be more
                appealing to visitors
              </p>
              <Link to="/admin/profile">
                <div className="flex">
                  <button className="flex hover:shadow-2xl hover:bg-black shadow-xl rounded-lg bg-red-500 p-3 mt-3 text-white">
                    <PencilAltIcon className="w-5 h-5 mr-2" /> Customize page
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" flex flex-wrap"></div>
      {/* <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardPageVisits />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardSocialTraffic />
        </div>
      </div> */}

      <Modal
        isOpen={modalIsOpen}
        appElement={document.getElementById("app")}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex p-2 justify-between">
          <h1 className="font-bold" style={{ fontSize: "23px" }}>
            Share your Fisitor link{" "}
          </h1>
          <button className="bg-red-600 rounded-full p-2" onClick={closeModal}>
            <XIcon
              style={{ color: "white" }}
              className="text-center w-full w-4 h-4"
            />
          </button>
        </div>
        <div className="p-2">
          <ShareLink />
        </div>
      </Modal>

      <Modal
        isOpen={modalMobileIsOpen}
        appElement={document.getElementById("app")}
        onRequestClose={closeMobileModal}
        style={customMobileStyles}
        contentLabel="Example Modal"
      >
        <div className="flex p-2 justify-between">
          <h1 className="font-bold" style={{ fontSize: "23px" }}>
            Share your link
          </h1>
          <button
            className="bg-red-600 rounded-full p-2"
            onClick={closeMobileModal}
          >
            <XIcon
              style={{ color: "white" }}
              className="text-center w-full w-3 h-3"
            />
          </button>
        </div>
        <div className="p-2">
          <ShareLinkMobile />
        </div>
      </Modal>
      <BottomMenu user={users}/>
    </>
  );
}
