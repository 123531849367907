import React, {Component} from "react";
import "tailwindcss/tailwind.css"
import {createUserWithEmailAndPassword,updateProfile, sendEmailVerification
  // ,getAuth,onAuthStateChanged
} from 'firebase/auth'
import {auth} from '../../firebase-auth'
import Swal from "sweetalert2";  
import slugify from 'react-slugify';
import { doc,getDoc,setDoc } from 'firebase/firestore'
import {db} from '../../db'
import { withRouter } from 'react-router-dom';
import moment from 'moment'
import { EyeOffIcon } from '@heroicons/react/solid'
import {EyeIcon} from '@heroicons/react/solid'
import LoadingOverlay from 'react-loading-overlay';


export class Signup extends Component {
  state = {
   firstName:"",
   passwordShown:false,
   isActive:false,
   lastName:"",
   companyName:"",
   email:"",
   password:"",
   createButton:"Create Account",
   phoneNumber:"",
   image:"https://firebasestorage.googleapis.com/v0/b/visitor-app-a45ed.appspot.com/o/images%2Foffice-building.png?alt=media&token=99c23002-f343-40e5-9527-3175e50903f5"
  }

  handleChange = (e)=>{
    this.setState({
     [e.target.id]:e.target.value
    })
  }

  // componentDidMount(){
  //   const auth = getAuth()
  //   onAuthStateChanged(auth, (user) =>{
  //  if(user != null && localStorage.getItem("fisitorKey") != null){
  //    this.props.history.push("/pricing")
  //  }
  // })

  // }

  togglePassword = () =>{
    this.setState({
      passwordShown : !this.state.passwordShown
    })
  }

  login = () =>{
    this.props.history.push('/login')
  }

 handleSubmit = async (e)=>{
  e.preventDefault()
  this.setState({isActive:true})
  this.setState({createButton:"Loading..."})
   if(this.state.firstName === ''){
    Swal.fire({  
      title: 'Registration Invalid',  
      text: "Please enter your first name",  
      icon: 'error',  
      confirmButtonColor: '#eb3f3f',   
      confirmButtonText: 'Ok'
    }); 
  this.setState({isActive:false})
  this.setState({createButton:"Create Account"})
   }
   else if(this.state.lastName === ''){
    Swal.fire({  
      title: 'Registration Invalid',  
      text: "Please enter your last name",  
      icon: 'error',  
      confirmButtonColor: '#eb3f3f',   
      confirmButtonText: 'Ok'
    }); 
    this.setState({isActive:false})
    this.setState({createButton:"Create Account"})
   }
   else if(this.state.email === ''){
    Swal.fire({  
      title: 'Registration Invalid',  
      text: "Please enter your email",  
      icon: 'error',  
      confirmButtonColor: '#eb3f3f',   
      confirmButtonText: 'Ok'
    }); 
   }
   else if(this.state.password === ''){
    Swal.fire({  
      title: 'Registration Invalid',  
      text: "Please enter your password",  
      icon: 'error',  
      confirmButtonColor: '#eb3f3f',   
      confirmButtonText: 'Ok'
    }); 
    this.setState({isActive:false})
    this.setState({createButton:"Create Account"})
   }

 else{
    try{
      createUserWithEmailAndPassword(auth,this.state.email,this.state.password)
      .then(async()=>{
        const docRef = doc(db,'company', slugify(this.state.companyName));
        const docSnap = await getDoc(docRef);
        if (docSnap._document != null) {
          Swal.fire({
            title: "Username already taken",
            text:
              "This name has already been taken. Please use another one",
            icon: "error",
            confirmButtonText: "Okay"
          });
          this.setState({createButton:"Create Account"})
        } else {
          setDoc(docRef, {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            companyName:this.state.companyName,
            address:"Nigeria",
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            image:this.state.image,
            color:"#eb3f3f",
            employees:[
              {name:this.state.firstName+" "+this.state.lastName,
              email:this.state.email,
              phone:this.state.phoneNumber,
              role:"Owner",
              department:this.state.companyName
            }
            ],
            visitors:[],
            schedule:[],
            notifications: [
              {
                title: "Welcome to Fisitor App!",
                message:
                  "Congratulations, You have successfully created an account on Fisitor",
                timestamp: moment(Date.now()).format("LLL")
              }
            ],
            uid: auth.currentUser.uid,
            photoURL:this.state.image,
            expiryDate:"",
            pricingPlan:"",
            usedFree:false
          }).then(()=>{
            localStorage.setItem('fisitorKey',slugify(this.state.companyName))
            updateProfile(auth.currentUser, {
              displayName: this.state.companyName,
              photoURL: this.state.image
            });

            sendEmailVerification(auth.currentUser).then(()=>{
              Swal.fire({  
              title: 'Email Verification sent!',  
              text: "Please click the link sent to your email to verify your Fisitor Account",  
              icon: 'success',  
              confirmButtonColor: '#eb3f3f',   
              confirmButtonText: 'Open Email'
            }).then((result)=>{
              if(result.isConfirmed){
                window.location.href="https://gmail.com"
              }
            }) 
            })
         
            // this.props.history.push('/pricing')
            // window.location.reload()
          })
            .catch(error => {
              console.log(error);
               Swal.fire({
                title: "Something went wrong!",
                text:
                  "Please try again by refreshing the page. Sorry for the incoveniences 😥",
                icon: "error",
                confirmButtonText: "Ok"
              });
            });
        }
      }).catch((error) =>{
        if(error.message === "Firebase: Error (auth/email-already-in-use)."){
          Swal.fire({  
            title: 'Registration Invalid',  
            text: "This email has already been used by another account",  
            icon: 'error',  
            confirmButtonColor: '#eb3f3f',   
            confirmButtonText: 'Ok'
          })
          this.setState({isActive:false})
          this.setState({createButton:"Create Account"})
        } else if (error.message === "Firebase: Error (auth/invalid-email)."){
          Swal.fire({  
            title: 'Registration Invalid',  
            text: "Password should be at least 6 characters",  
            icon: 'error',  
            confirmButtonColor: '#eb3f3f',   
            confirmButtonText: 'Ok'
          })
          this.setState({isActive:false})
          this.setState({createButton:"Create Account"})
        }
      })
      
    } catch(error){
      console.log(error.message)
     if(error.message === "Firebase: Error (auth/email-already-in-use)."){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "This email has already been used by another account",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
    } else if (error.message === "Firebase: Password should be at least 6 characters (auth/weak-password)."){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Password should be at least 6 characters",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
    } else if (error.message === "Firebase: Error (auth/invalid-email)."){
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Password should be at least 6 characters",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      this.setState({isActive:false})
      this.setState({createButton:"Create Account"})
    }
    else{
      Swal.fire({  
        title: 'Registration Invalid',  
        text: "Password should be at least 6 characters",  
        icon: 'error',  
        confirmButtonColor: '#eb3f3f',   
        confirmButtonText: 'Ok'
      })
      this.setState({isActive:false})
      this.setState({createButton:"Create Account"})
    }
   
    }
  }
  
  }


  render() {
  return (
    <div>
      <LoadingOverlay
  active={this.state.isActive}
  spinner
  text='Loading...'
  >
  <p>.</p>
      <div style={{fontFamily:"Montserrat"}} className="container mt-5 mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-6/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-3xl bg-blue-50 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h1 style={{fontSize:"20px"}} className="text-red-500 font-bold">
                    Create Account on Fisitor
                  </h1>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            
                <form onSubmit={this.handleSubmit}>

                  <div className="flex relative w-full mb-9">
                    <div className="w-1/2 h-12 mr-4">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                     First Name
                    </label>
                    <input
                      id="firstName"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Your First Name"
                      onChange = {this.handleChange}
                    />
                    </div>
                    <div className="w-1/2 h-12">
                     <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Last Name
                    </label>
                    <input
                      id="lastName"
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Your Last Name"
                      onChange = {this.handleChange}
                    />
                    </div>
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                     Company Name
                      </label>

                    <input
                      id="companyName"
                      type="companyName"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Company Name"
                      onChange = {this.handleChange}
                    />
                   
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                      </label>

                    <input
                      id="email"
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                      onChange = {this.handleChange}
                    />
                   
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Phone Number
                      </label>

                    <input
                      id="phoneNumber"
                      type="number"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Phone Number"
                      onChange = {this.handleChange}
                    />
                   
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Password
                    </label>
                    <input
                      id="password"
                      type={this.state.passwordShown ? "text" : "password"}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                      onChange = {this.handleChange}
                    >
                      </input>
                     {this.state.passwordShown && <EyeOffIcon onClick={this.togglePassword} style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      }
                       {!this.state.passwordShown && <EyeIcon onClick={this.togglePassword} style={{cursor:"pointer"}} className="w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-3" />
                      }
                 </div>

                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blue-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-red-500">
                        I agree with the{" "}
                        <a
                          href="#pablo"
                          className="text-red-500"
                          onClick={(e) => e.preventDefault()}
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <button
                      className="animate__animated animate__fadeInUp bg-red-500 text-white hover:bg-red-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={this.handleSubmit}
                    >
                      {this.state.createButton}
                    </button>
                  </div>
                  <p className="text-center mt-3">Already have an account? <span onClick={this.login} style={{color:"#eb3f3f",cursor:"pointer",fontWeight:"bold"}}>Login </span></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      </LoadingOverlay>
    </div>
  );
}
}


export default withRouter(Signup)