import { updateDoc, doc, arrayUnion} from "@firebase/firestore";
import React, { Component } from "react";
import { db } from "../../db";
import LoadingOverlay from "react-loading-overlay";



export class Form extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    department: "",
    role: "",
    isActive:false,
    date:"",
    whomtosee:""
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleSubmit = () => {
    this.setState({isActive:true})
    const schedulePayLoad = {
      name: this.state.firstName + " " + this.state.lastName,
      phone: this.state.phoneNumber,
      email: this.state.email,
      whotosee:this.state.whomtosee,
      date:this.state.date + " " + this.state.time,
      timestamp: {nanoseconds:0, seconds:new Date(this.state.date + " "+ this.state.time).getTime()/1000}
    };

    console.log(schedulePayLoad)

    const docRef = doc(db, 'company', localStorage.getItem("fisitorKey"));
    updateDoc(docRef, {
      schedule: arrayUnion(schedulePayLoad),
    }).then(() => {
      this.setState({isActive:true})
      window.location.reload();
    });
  };

  render() {
    return (
      <LoadingOverlay active={this.state.isActive} spinner text="Loading...">
        <p>.</p>
        <>
          <div>
            <div className="">
              <div className="mt-5 md:mt-0 md:col-span-2">
                <form className="mb-6">
                  <div className="flex relative w-full mb-9">
                    <div className="w-1/2 h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        First Name
                      </label>
                      <input
                        id="firstName"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="First Name"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="w-1/2 h-12">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Last Name
                      </label>
                      <input
                        id="lastName"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Last Name"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="flex relative w-full mb-9">
                    <div className="w-1/2 h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        id="email"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="w-1/2 h-12">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Phone 
                      </label>
                      <input
                        id="phoneNumber"
                        type="number"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Phone Number"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Whom to see
                          </label>

                          {this.props.users && (
                            <select
                              id="whomtosee"
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Whom to see"
                              onChange={this.handleChange}
                            >
                              <option value="" selected="selected">Choose here</option>
                              {this.props.users.employees.map((employee, i) => (
                                <option selected={employee.name} key={i} defaultValue={employee.name} value={employee.name}>
                                  {employee.name}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>

                  <div className="flex relative w-full mb-9">
                    <div className="w-1/2 h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Date
                      </label>
                      <input
                        id="date"
                        type="date"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Pick a date"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="w-1/2 h-12">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Time
                      </label>
                      <input
                        id="time"
                        type="time"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Pick a time"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {/* <div className="flex relative w-full mb-9">
                    <div className="w-1/2 h-12 mr-4">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Department
                      </label>
                      <input
                        id="department"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="department"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="w-1/2 h-12">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Role
                      </label>
                      <input
                        id="role"
                        type="text"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="role"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div> */}
                </form>
                <div className="text-center">
                    <button
                      className="mt-4 bg-red-500 text-white hover:bg-blue-900 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                      onClick={this.handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
              </div>
            </div>
          </div>
        </>
      </LoadingOverlay>
    );
  }
}

export default Form;
