import React, { useState } from "react";
import "./Badge.css"

function Badge() {
  const [name] = useState(JSON.parse(localStorage.getItem("visitor")).name);
  const [image] = useState(JSON.parse(localStorage.getItem("visitor")).image);
  // const [time] = useState(JSON.parse(localStorage.getItem("visitor")).time);
  // const [phoneNumber] = useState(
  //   JSON.parse(localStorage.getItem("visitor")).phone
  // );
  // const [email] = useState(JSON.parse(localStorage.getItem("visitor")).email);
  const [whomtosee] = useState(
    JSON.parse(localStorage.getItem("visitor")).whotosee
  );
  const [visitorLink] = useState(JSON.parse(localStorage.getItem("visitor")).visitorLink);
  const [timeIn] = useState(JSON.parse(localStorage.getItem("visitor")).timeIn);
  const [timeOut] = useState(JSON.parse(localStorage.getItem("visitor")).timeOut);
  const [floorNo] = useState(JSON.parse(localStorage.getItem("visitor")).floorNo);
  return (
    <div>
      {/* <div
        style={{ maxWidth: "400px", margin: "auto", marginTop: "100px" }}
        className="text-center shadow-lg rounded-lg"
      >
        <h2 className="rounded-t-lg mb-5 mx-auto bg-green-500 text-white p-2">
          Visitor badge
        </h2>
        <div style={{marginTop:"30px"}}>
          <img
            src={image}
            className="infront"
            style={{height:"130px", margin: "auto",borderRadius:"100%",border:"4px solid #163351" }}
            width="130px"
            alt=""
          />
        </div>
        <div style={{transform: "skewY(-10deg)",padding:"0px 60px 80px 0px",background:"#163351",marginTop:"-50px",paddingTop:"20px"}} className="behind text-white p-3">

        </div>
        <div style={{background:"#163351",marginTop:"-33px"}} className="rounded-lg text-white p-3">
          <p className="infront text-white text-2xl font-bold pb-4">{name}</p>
          <div className="pb-2">
            <div style={{width:"80px",fontSize:"12px",color:"#163351"}} className="bg-white rounded-full px-4 mx-auto">email:</div> <p>{email}</p>
          </div>
          <div>
          <div style={{width:"80px",fontSize:"12px",color:"#163351"}} className="bg-white rounded-full px-4 mx-auto">phone:</div> <p>{phoneNumber}</p>
          </div>
          <div className="mt-3">
          <div style={{width:"103px",fontSize:"12px",color:"#163351"}} className="bg-white rounded-full px-4 mx-auto">whom to see:</div>
          </div>
          <p>{whomtosee}</p>
        </div>
      </div> */}

      <div className="shadow-lg px-1 rounded-xl" style={{width:"500px",margin:"auto", height:"240px",marginTop:"10%"}}>
         <div className="bg-red-500 text-center text-white p-1 font-bold text-sm">VISITOR</div>
         
         <div className="flex justify-around">
          <div style={{marginRight:"80px"}}> 
            <div className="text-center mt-4">
            <div className="p-2 text-black">
            <h2  style={{fontSize:"19px"}} className="font-bold text-sm m-0">NAME:</h2> 
            </div>
            <p style={{fontSize:"19px"}} className="-mt-4">{name}</p>
            </div>
            <div style={{fontSize:"13px"}} className="bg-black text-white text-center p-1">{timeIn} - {timeOut}</div>
            <div className="text-center">
            <p className="font-bold text-md" style={{fontSize:"19px",marginBottom:"-6px",paddingBottom:"-6px"}}>Whom to see:</p>
            <p className="text-xs" style={{fontSize:"18px",marginTop:"0px",paddingTop:"0px"}}>{whomtosee}</p>
            <div style={{marginTop:"0px",paddingTop:"0px"}}>
            <p className="font-bold text-xl" style={{fontSize:"12px",marginBottom:"0px",paddingBottom:"0px"}}>Floor No:</p>
            <p className="text-xs" style={{fontSize:"16px",marginTop:"-6px",paddingTop:"-6px"}}>{floorNo}</p>
            </div>
            </div>
          </div>


          <div className="mt-2"> 
          <div className="mb-1">
          <img
            src={image}
            className="infront"
            style={{height:"80px",margin: "auto",border:"1px solid #163351",objectFit:"cover"}}
            width="80px"
            alt=""
          />
          </div>
            <img
            alt="qrcode"
            style={{margin: "auto",border:"1px solid" }}
            width="80px"
            height="80px"
            src={"https://www.qrtag.net/api/qr_2.svg?url="+visitorLink}
          />
          </div>
         </div>
      </div>



      <div className="text-center">
      <button className="p-3 shadow-lg border-red-500 no-print" onClick={()=>window.print()}>Print Badge</button>
      </div>
    </div>
  );
}

export default Badge;
