import React, { useState,useEffect } from "react";
import BottomMenu from "../../components/BottomMenu/BottomMenu";
import Sidebar from "../../components/Sidebar/Sidebar";
// import { doc, updateDoc,arrayRemove } from "firebase/firestore";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch,useSelector } from "react-redux";
import { getUsers } from "../../redux/user";
import Modal from 'react-modal';
import { XIcon } from "@heroicons/react/solid";
import { ExclamationIcon } from "@heroicons/react/solid";
import styles from "./Styles"
import ScheduleTabs from "./ScheduleTabs"
import AddSchedule from "./AddSchedule"



function Visitors() {
  // const [employees] = React.useState("");
  const [isActive] = React.useState(false);
  const[employee] = useState("")


  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [deletemodalIsOpen, setdeleteModal] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  
  function closeModal() {
    setIsOpen(false);
  }


  function closedeleteModal() {
    setdeleteModal(false);
  }

//   function deleteRow(e){
//     console.log('omo')
//     setEmployee(e)
//     setdeleteModal(true)

//   }


//  async function deleteEmployee(){
//     const docRef = doc(db,"visitors",localStorage.getItem("fisitorKey"))
//     await updateDoc(docRef,{
//       employees:arrayRemove(employee)
//     })
//   }


  const { users } = useSelector((state) => state.users);

  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <LoadingOverlay active={isActive} spinner text="Loading...">
      <p>.</p>
      <>
        {/* Laptop view */}
        <div className="hidden lg:block">
          <div className=" grid grid-rows-3 grid-flow-col gap-4">
            <div className="hidden lg:block xl:block 2xl:block row-span-3" style={{zIndex:0}}>
              <Sidebar user={users} />
            </div>
            <div className="font-poppins col-span-2">
              <div className="flex p-6 justify-between ">
                <h1 className="font-bold text-xl">Your Schedule</h1>
                <button
                  type="button"
                  className="px-4 py-3 bg-red-500 rounded-md text-white outline-none focus:ring-4 shadow-lg transform active:scale-x-75 transition-transform mx-5 flex"
                onClick={openModal}
               >
                  <i className="fas fa-plus h-2 m-1 mr-5 w-2"></i>

                  <span className="ml-2">Add Schedule</span>
                </button>
              </div>
              <div style={{width:"710px"}} className="m-auto p-2 mx-6 mb-8 overflow-hidden rounded-lg">
                <div  className="mb-7 overflow-x-auto">
                 {users && <ScheduleTabs users={users}/>}
                </div> 
              </div>
              {/* <!-- The Modal --> */}
              <Modal
        isOpen={modalIsOpen}
        appElement={document.getElementById('root') || undefined}
        onRequestClose={closeModal}
        style={styles.customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex p-2 justify-between">
          <h1 className="font-bold" style={{fontSize:"23px"}}>Add Schedule </h1>
        <button className="bg-red-600 rounded-full p-2" onClick={closeModal}>
          <XIcon style={{color:"white"}}  className="text-center w-full w-4 h-4"/>
        </button>
        </div>
        <div className="p-2">
         <AddSchedule users={users}/>
        </div>
      </Modal>
      
      {/* Are you sure you want to delete modal? */}
      <Modal
        isOpen={deletemodalIsOpen}
        appElement={document.getElementById('app')}
        onRequestClose={closedeleteModal}
        style={styles.customStyles}
      >
        <div className="flex p-2 justify-between">
          <h1 className="font-bold" style={{fontSize:"23px"}}>Delete {employee.name}? </h1>
        <button className="bg-red-600 rounded-full p-3" onClick={closedeleteModal}>
          <XIcon style={{color:"white"}}  className="text-center w-full w-4 h-4"/>
        </button>
        </div>

        <div className="p-2">
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h2 as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Deactivate account
                    </h2>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to deactivate your account? All of your data will be permanently removed.
                        This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  // onClick={() => deleteEmployee()}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setdeleteModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
        </div>
      </Modal>
      {/* End of Modal */}
            </div>
          </div>
        </div>

        {/* Mobile View */}
        <div className="lg:hidden xs:block" style={{ marginBottom: "400px" }}>
          <div className="flex p-6 justify-between ">
            <h1 className="font-bold text-xl">Schedule</h1>
            <button
              onClick={openModal}
              className="px-4 py-3 bg-red-500 rounded-md text-white outline-none focus:ring-4 shadow-lg transform active:scale-x-75 transition-transform mx-5 flex"
            >
              <i className="fas fa-plus h-2 m-1 mr-5 w-2"></i> Add Schedule
            </button>
          </div>
          <div className="mx-6 mb-8 overflow-hidden rounded-lg shadow-lg">
            <div className="mb-7 w-full overflow-x-auto">
             {users &&<ScheduleTabs users={users} />}
            </div>
          </div>
        </div>
        <div>
          <BottomMenu user={users} />
        </div>
      </>
    </LoadingOverlay>
  );
}

export default Visitors;
