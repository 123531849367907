import React, { Component } from "react";
import LoadingOverlay from "react-loading-overlay";
import { unslugify } from "unslugify";
import { withRouter } from "react-router-dom";
import "./Styles.css";
import { updateDoc, doc, arrayUnion } from "@firebase/firestore";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadString,
} from "firebase/storage";
import Swal from "sweetalert2";
import { db } from "../../db";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import Modal from "react-modal";
import  styles from './Styles'
import axios from "axios"
import slugify from "react-slugify";

export class UserForm extends Component {
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  state = {
    companyName: unslugify(this.props.location.pathname.slice(0, -5).slice(1)),
    firstName: "",
    modalIsOpen:false,
    isActive: false,
    lastName: "",
    email: "",
    laptopNo: "",
    floorNo:"",
    vehicleNo: "",
    createButton: "Submit",
    phoneNumber: "",
    whomtosee: "",
    timeIn: "",
    timeOut:"",
    hourIn:"",
    minuteIn:"",
    meridianIn:"",
    hourOut:"",
    minuteOut:"",
    meridianOut:"",
    date: "",
    visitorLink:window.location,
    uploadedImage: "",
    image:
      "https://firebasestorage.googleapis.com/v0/b/visitor-app-a45ed.appspot.com/o/user.png?alt=media&token=b3d66519-ba73-4b1a-a64e-1e2813249c15",
  };

  handleSubmit = async (e) => {
    this.setState({ isActive: true });
    var timeSplit = this.state.timeIn.split(':')
    this.state.hourIn = timeSplit[0]
    this.state.minuteIn = timeSplit[1]
  if (this.state.hourIn > 12) {
    this.state.meridianIn = 'PM';
    this.state.hourIn -= 12;
  } else if (this.state.hourIn < 12) {
    this.state.meridianIn = 'AM';
    if (this.state.hourIn === 0) {
    this.state.hourIn = 12;
    }
  } else {
    this.state.meridianIn = 'PM';
  }

  var timeSplitOut = this.state.timeOut.split(':')
  this.state.hourOut = timeSplitOut[0]
  this.state.minuteOut = timeSplitOut[1]
if (this.state.hourOut > 12) {
  this.state.meridianOut = 'PM';
  this.state.hourOut -= 12;
} else if (this.state.hourOut < 12) {
  this.state.meridianOut = 'AM';
  if (this.state.hourOut === 0) {
  this.state.hourOut = 12;
  }
} else {
  this.state.meridianOut = 'PM';
}




    e.preventDefault();
    if(this.state.image === "https://firebasestorage.googleapis.com/v0/b/visitor-app-a45ed.appspot.com/o/user.png?alt=media&token=b3d66519-ba73-4b1a-a64e-1e2813249c15"){
      Swal.fire({
       title: "Registration invalid",
       text:
         "You need to snap a selfie",
       icon: "error",
       confirmButtonColor: "#eb3f3f",
       confirmButtonText: "Ok",
      })
      this.setState({ isActive: false });
    }

   else if(this.state.firstName === ""){
     Swal.fire({
      title: "Registration invalid",
      text:
        "You need to enter your first name",
      icon: "error",
      confirmButtonColor: "#eb3f3f",
      confirmButtonText: "Ok",
     })
     this.setState({ isActive: false });
   }

   else if(this.state.lastName === ""){
    Swal.fire({
     title: "Registration invalid",
     text:
       "You need to enter your last name",
     icon: "error",
     confirmButtonColor: "#eb3f3f",
     confirmButtonText: "Ok",
    })
    this.setState({ isActive: false });
  }

  else if(this.state.email === ""){
    Swal.fire({
     title: "Registration invalid",
     text:
       "You need to enter your email",
     icon: "error",
     confirmButtonColor: "#eb3f3f",
     confirmButtonText: "Ok",
    })
    this.setState({ isActive: false });
  }

  else if(this.state.phoneNumber === ""){
    Swal.fire({
     title: "Registration invalid",
     text:
       "You need to enter your phone number",
     icon: "error",
     confirmButtonColor: "#eb3f3f",
     confirmButtonText: "Ok",
    })
    this.setState({ isActive: false });
  }
  
  else if(this.state.whomtosee === ""){
    Swal.fire({
     title: "Registration invalid",
     text:
       "You need to specify who you want to see",
     icon: "error",
     confirmButtonColor: "#eb3f3f",
     confirmButtonText: "Ok",
    })
    this.setState({ isActive: false });
  }

    
  else if(this.state.date === ""){
    Swal.fire({
     title: "Registration invalid",
     text:
       "You need to specify date of visit",
     icon: "error",
     confirmButtonColor: "#eb3f3f",
     confirmButtonText: "Ok",
    })
    this.setState({ isActive: false });
  }

    
  else if(this.state.timeIn === ""){
    Swal.fire({
     title: "Registration invalid",
     text:
       "You need to specify your time-in",
     icon: "error",
     confirmButtonColor: "#eb3f3f",
     confirmButtonText: "Ok",
    })
    this.setState({ isActive: false });
  }
 
    else{
    const visitorPayLoad = {
      name: this.state.firstName + " " + this.state.lastName,
      phone: this.state.phoneNumber,
      email: this.state.email,
      image: this.state.uploadedImage,
      whotosee: this.state.whomtosee,
      floorNo:this.state.floorNo,
      laptop: this.state.laptopNo,
      vehicle: this.state.vehicleNo,
      status:"checked in",
      timeIn:this.state.hourIn+":"+this.state.minuteIn+" "+this.state.meridianIn,
      timeOut:this.state.hourOut+":"+this.state.minuteOut+" "+this.state.meridianOut
    };


    localStorage.setItem("visitor",JSON.stringify(visitorPayLoad))

    const schedulePayLoad = {
      name: this.state.firstName + " " + this.state.lastName,
      phone: this.state.phoneNumber,
      email: this.state.email,
      date: this.state.date + " " + this.state.time,
      timestamp: {
        nanoseconds: 0,
        seconds:
          new Date(this.state.date + " " + this.state.time).getTime() / 1000,
      },
      image: this.state.uploadedImage,
      whotosee: this.state.whomtosee,
      laptop: this.state.laptopNo,
      vehicle: this.state.vehicleNo,
    };

    const docRef = doc(
      db,
      "company",
      this.props.location.pathname.slice(0, -5).slice(1)
    );
    updateDoc(docRef, {
      visitors: arrayUnion(visitorPayLoad),
      schedule: arrayUnion(schedulePayLoad),
    }).then(() => {
      axios({
       method:"post",
       url:"https://fisitor-api.herokuapp.com/mail",
       data: {
        from: this.state.companyName + " <hello@trot.ng>",
        email: this.props.users.email,
        replyTo: this.state.email,
        title: this.state.firstName +" has scheduled a meeting at your office",
        message: `<div style="margin:0px 30px 0px 30px">
        <div style="text-align:center;width:50%:margin:auto">
        <div style=" text-align: center; width:300px; margin:auto auto 20px auto"> 
        <div style="position: relative;top: 10px;color: white;font-size: 13px;background: white;display: inline-block;padding: 0 20px;border-radius: 30px;">
        <img src=${this.props.users.image} width="60px" height="60px" style="width:60px;text-align:center;margin:auto;border-radius:50%"/>
        </div>
        <div style="margin-top:20px;height:1px;background:black"></div>
      </div>
       </div>
        <h2 style="margin-top:20px">Hello ${this.state.companyName}</h2>
       <p> ${this.state.firstName} ${this.state.lastName} just checked in for a meeting in your office with <b>${this.state.whomtosee}</b> </p>
      <div style="margin:20px 0px 60px 0px">
       <p> Here are the details of the visit:</p>
      <p>Whom to see: <b>${this.state.whomtosee}</b> </p>
      <p>Date of visit: <b>${this.state.date}</b></p>
      <p>Time of visit: <b>${this.state.timeIn}</b></p>
      <p>Laptop: <b>${this.state.laptopNo}</b></p>
      <p>Vehicle No: <b>${this.state.vehicleNo}</b></p>
      </div>
      <div>
      <p>Have a great day,</p>
      <p>Your partners at Fisitor Management System </p>
      </div>
        <div style="background:black,color:${this.props.users.color},padding:400px;display:flex">
        <div style="margin-top:60px;background-color: ${this.props.users.color}; color: black; text-align: center; padding: 30px 20px 30px 20px;">
        <p style="text-align: center; color: white;">
        <a style="color: #000000;margin-right:15px" href="https://facebook.com/trot.ng">
        <img class="alignnone wp-image-28420" src="https://i.ibb.co/FzDN2DT/facebook-1.png" alt="" width="22" height="22" />
        </a>
        
        
        <a style="color: #000000;margin-right:15px" href="https://twitter.com/trot.ng">
        <img class="alignnone wp-image-28421" src="https://i.ibb.co/yF5Hq15/twitter-1.png" alt="" width="21" height="21" />
        </a>
        
        <a style="color: #000000;margin-right:15px" href="https://instagram.com/trot.ng">
        <img class="alignnone wp-image-28422" src="https://i.ibb.co/HYMFS5D/instagram-1.png" alt="" width="21" height="21" />
        </a>
        
        <a style="color: #000000;margin-right:15px" href="https://linkedin.com/in/trot.ng">
        <img class="alignnone wp-image-28423" src="https://i.ibb.co/nsJMFYn/linkedin-1.png" alt="" width="21" height="21" />
        </a>
        
        <a style="color: #000000;margin-right:15px" href="https://www.youtube.com/trot.ng">
        <img class="alignnone wp-image-28424" src="https://i.ibb.co/7121z7Y/youtube.png" alt="" width="21" height="21" />
        </a>
        
        </p>
        </div>
        </div>
        
        </div>`
      }
    })
      .then(response => {
        console.log(response)
        this.setState({ isActive: false });
        Swal.fire({
          title: "You've been checked in",
          text:
            "Your visit to " +
            this.state.companyName +
            " has been successfully scheduled. Enjoy your meeting",
          icon: "success",
          confirmButtonColor: "#eb3f3f",
          confirmButtonText: "Ok",
          confirmButtonLink:"/"+slugify(this.state.companyName)
        })
        if(this.props.users.pricingPlan === "Business"){
        this.props.history.push('/badge')
        }
      })
      .catch(error => {
        console.log(error);
        this.btnloading = false;
        this.$swal({
          title: "Email Not Sent",
          text: "Sorry, we couldn't send your email due to a network error.",
          icon: "error",
          confirmButtonText: "Ok"
        });
      });
    
    });
  }
  };

  closeModal = () =>{
    this.setState({modalIsOpen:false})
  }

  openModal = (e) =>{
    e.preventDefault()
    this.setState({modalIsOpen:true})
  }

  goBack = (e) =>{
   e.preventDefault()
   this.props.history.go(-1)
  }

   handleTakePhoto =(dataUri) => {
    this.setState({image:dataUri})
    this.onFileChanged(dataUri)
    this.setState({modalIsOpen:false})
   }

  onFileChanged = (event) => {
    const storage = getStorage();
    // Create the file metadata
    /** @type {any} */
  
    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, "images/" + (Math.random() + 1).toString(36).substring(7));
    const uploadTask = uploadString(storageRef, event, 'data_url')
    .then((snapshot) => {
      console.log(uploadTask);
      getDownloadURL(snapshot.ref)
      .then((downloadURL) => {
        this.setState({ uploadedImage: downloadURL });
        this.setState({ isActive: false });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Something went wrong 😥",
          text: "Sorry, we couldn't update your profile picture. It could be a network issue",
          icon: "error",
          confirmButtonText: "Yay!",
        });
      });
    });
  };

  render() {
    return (
      <div>
        <div>
          <LoadingOverlay
            active={this.state.isActive}
            spinner
            text="Loading..."
          >
            <p>.</p>
           
            <div className="container mt-5 mx-auto px-4 h-full">
            <button onClick={this.goBack}> <i className="fas fa-arrow-left"> </i> Back</button>
              <div className="flex content-center items-center justify-center h-full">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="mt-12 relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-3xl sborder-0">
                    <img
                      src={this.props.users.image}
                      alt=""
                      className="avatar"
                    />
                    <div className="rounded-t mb-0 px-6 py-6">
                      <div className="text-center mb-3">
                        <h1
                          style={{
                            fontSize: "20px",
                            color: this.props.users.color,
                          }}
                          className="font-bold"
                        >
                          Schedule a meeting at {this.state.companyName}'s
                          office
                        </h1>
                      </div>
                      <hr className="mt-6 border-b-1 border-blueGray-300" />
                    </div>
                    <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                      <form onSubmit={this.handleSubmit}>
                        <div className="flex mb-7">
                          <img
                            id="image"
                            src={this.state.image}
                            alt=""
                            className="userAvatar"
                          />
                          <input
                            hidden
                            type="file"
                            id="image"
                            accept="image/*"
                            onChange={this.onFileChanged}
                          />
                          <button
                            onClick={this.openModal}
                            style={{ width: "120px" }}
                            className="ml-2 mt-2 h-12 text-xs shadow border rounded-lg border-blue"
                          >
                            Take Selfie
                          </button>
                        </div>

                        <div className="flex relative w-full mb-9">
                          <div className="w-1/2 h-12 mr-4">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              First Name
                            </label>
                            <input
                              id="firstName"
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Your First Name"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="w-1/2 h-12">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="grid-password"
                            >
                              Last Name
                            </label>
                            <input
                              id="lastName"
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Your Last Name"
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Email
                          </label>

                          <input
                            id="email"
                            type="email"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Email"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Phone Number
                          </label>

                          <input
                            id="phoneNumber"
                            type="number"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Phone Number"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Office Address
                          </label>

                          <input
                            id="address"
                            type="address"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Office Address"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Whom to see
                          </label>

                          {this.props.users && (
                            <select
                              id="whomtosee"
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Whom to see"
                              onChange={this.handleChange}
                            >
                              <option value="" selected="selected">Choose here</option>
                              {this.props.users.employees.map((employee, i) => (
                                <option selected={employee.name} key={i} defaultValue={employee.name} value={employee.name}>
                                  {employee.name}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>


                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Floor No. (optional)
                          </label>

                          <input
                            id="floorNo"
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Floor Number"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Laptop Serial No. (optional)
                          </label>

                          <input
                            id="laptopNo"
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Laptop serial no."
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Vehicle Registration No. (optional)
                          </label>

                          <input
                            id="vehicleNo"
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Vehicle Registration No."
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Date of visit
                          </label>

                          <input
                            id="date"
                            type="date"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Date of visit"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Time in
                          </label>

                          <input
                            id="timeIn"
                            type="time"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Time in"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            Time Out
                          </label>

                          <input
                            id="timeOut"
                            type="time"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Time in"
                            onChange={this.handleChange}
                          />
                        </div>

                        <div className="text-center mt-6">
                          <button
                            className="bg-red-500 text-white hover:bg-blue-900 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            type="button"
                            style={{ background: this.props.users.color }}
                            onClick={this.handleSubmit}
                          >
                            {this.state.createButton}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <Modal
                isOpen={this.state.modalIsOpen}
                appElement={document.getElementById('root') || undefined}
                onRequestClose={this.closeModal}
                style={styles.customStyles}
                contentLabel="Example Modal"
                >
                  <Camera
                    onTakePhoto={(dataUri) => {
                      this.handleTakePhoto(dataUri);
                    }}
                  />
                </Modal>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

export default withRouter(UserForm);
